export class VersionMappingData {
  mapFields: Array<MappingField>;
}

export class MappingField {
  id?: string;
  toolId?: string;
  name: string;
  format?: string;
  descriptor?: string;
  controlName?: string;
  childControlName?: string;
  toolType?: string;
  controlType?: string;
  type: string | MappingFieldType;
}

export enum MappingFieldType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
}
