export enum PropertyType {
  Text = 1,
  RichText,
  Name,
  HideOption,
  FormOptions,
  MailOptions,
  LetterOptions,
  UploadOptions,
  Scores,
  YnScores,
  ListAnswersLimit,
  TableToolOptions,
  EnableDecision,
  Conditions,
  ExcelAIMapping,
  ExcelAIMode,
  ExcelAILocalFileSelector,
  ExcelAISharePointFileSelector,
  ExcelAIUploadRequired,
}

export enum PropertyPlacement {
  Subheader = 1,
  PropertiesTab,
  Hint,
  Body,
}
