import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { nameIsUniqueValidatorFactory } from 'src/app/core/validators/name-is-unique.validator';
import { shouldBeInBrackets } from 'src/app/core/validators/should-be-in-brackets.validator';
import { IUndoableState } from '../../../../../workflow-designer/domain/undoable-state';
import { EyInputComponent } from 'src/app/shared/components/ey-input/ey-input.component';
import { FormBuilderState } from '../../../form-builder.state';
import { CONTROL_TYPE_TO_NAME_PREFIX } from '../../../control-type-map';
import { IFieldMeta } from 'src/app/shared/components/ey-base-form-control/field-meta.model';
import { MappingField } from 'src/app/modules/version/version-mapping-data.model';

const NON_EMPTY_NAME_REQUIRED = `Please fill the name of option, it can't be empty.`;
const UNIQUE_NAME_REQUIRED = 'Name already exists in this Version. Unique name is required.';

@Component({
  selector: 'app-form-builder-name',
  templateUrl: './form-builder-name.component.html',
  styleUrls: ['./form-builder-name.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormBuilderNameComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormBuilderNameComponent),
      multi: true,
    },
  ],
})
export class FormBuilderNameComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {
  @ViewChild(EyInputComponent) input: EyInputComponent;
  @Input() isRequired = true;

  @Input() optionNames = false;
  @Input() activeOptionIndex: number = null;
  @Input() background: 'grey' | 'white' | null = 'white';
  @Input() set required(val) {
    if (val) {
      this.meta = { ...this.meta, required: val };
    }
  }
  @Input() suggestedName: string = null;

  // Don't move it under the form declaration
  // as it used to create async validator in the names$ observable
  private destroy$ = new Subject<boolean>();

  form = this.fbs.group({
    name: ['', [Validators.required, Validators.maxLength(150)], [nameIsUniqueValidatorFactory(this.names$)]],
  });

  meta: IFieldMeta = {
    title: 'Name',
  };

  nameCounts: any = null;

  initialName: string = null;

  private previousStatus: any = null;

  private activeControlIndex: number;

  constructor(
    private fbs: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<{ formBuilder: IUndoableState<FormBuilderState>; mappings: Array<MappingField> }>,
  ) {}

  ngAfterViewInit(): void {
    this.input.warning = this.showWarning;
    this.changeDetectorRef.detectChanges();

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.input.warning = this.showWarning;
      this.input.meta.errorMsg = this.getErrorMessage(this.form.controls.name.errors);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  get names$(): Observable<string[]> {
    return this.store.pipe(
      takeUntil(this.destroy$),
      select((s) => s.mappings),
      map((mappings) => mappings.map((m) => m.childControlName ?? m.controlName).filter((name) => name !== this.initialName)),
    );
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.nameControl.errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.previousStatus = this.form.status;

    this.form.statusChanges.subscribe((status) => {
      if (status !== this.previousStatus) {
        this.previousStatus = status;
        fn();
      }
    });
  }

  writeValue(obj: any): void {
    this.form.patchValue({ name: obj }, { emitEvent: false });
    this.initialName = obj;
  }

  onChange: (val: any) => void = () => {};
  onTouched: (val: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    const syncValidators = [Validators.maxLength(150)];
    if (this.isRequired) {
      syncValidators.push(Validators.required);
    }

    this.form = this.fbs.group({
      name: ['', [...syncValidators], [nameIsUniqueValidatorFactory(this.names$)]],
    });

    this.form.valueChanges.subscribe((v) => {
      if (this.form.valid) {
        this.onChange(v.name);
      }
    });

    this.store
      .pipe(
        takeUntil(this.destroy$),
        select((s) => s.formBuilder.present),
      )
      .subscribe((f) => {
        this.activeControlIndex = f.activeControlIndex;
        const activeControl = f.controls[this.activeControlIndex];
        if (this.suggestedName == null) {
          this.suggestedName = activeControl ? CONTROL_TYPE_TO_NAME_PREFIX.get(f.controls[this.activeControlIndex].type) : 'Unknown Property';
        }
      });
  }

  onBlur(): void {
    this.initialName = this.nameControl.value;
    this.onTouched(this.nameControl.value);
  }

  get showWarning(): boolean {
    return !this.nameControl.errors && !!shouldBeInBrackets(this.nameControl);
  }

  get nameControl(): AbstractControl {
    return this.form.controls.name;
  }

  private getErrorMessage(errors: ValidationErrors): string {
    if (errors?.required) {
      return NON_EMPTY_NAME_REQUIRED;
    }

    if (errors?.maxlength) {
      return `Max length of ${errors.maxlength.requiredLength} charecters exceed`;
    }

    return UNIQUE_NAME_REQUIRED;
  }
}
