import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Config, ConfigService } from 'src/app/core/services/configuration.service';
import { LoginService } from 'src/app/core/services/login.service';
import { ErrorType } from './error-type';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private config: Config = this.configService.getConfig();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private configService: ConfigService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(this.config.integrationEndpoints.graphApi)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 403) {
          if (err.error.Errors.some((e) => e.Payload && e.Payload.Type === ErrorType.UserEnrollmentError)) {
            const error = err.error.Errors.find((e) => e.Payload && e.Payload.Type === ErrorType.UserEnrollmentError);

            this.loginService.logout(`${this.config.environment.redirectUrl}/access-denied/${error.Payload.Data.EnrollmentStatus}`);
          } else {
            this.loginService.logout(`${this.config.environment.redirectUrl}/access-denied`);
          }
        }

        throw err;
      }),
      map((event) => {
        if (event instanceof HttpResponse) {
          if (event.url.includes(environment.baseUrl)) {
            return new HttpResponse({ ...event, body: event.body?.payload === undefined ? event.body : event.body?.payload });
          } else {
            return event;
          }
        }
      }),
    );
  }
}
