import { Component, Input } from '@angular/core';
import { IFieldMeta } from '../ey-base-form-control/field-meta.model';

export const ButtonClass = {
  primary: 'btn--primary',
  secondary: 'btn--secondary',
  btnDelete: 'btn--delete',
  btnLinkWhite: 'btn--link btn--link__white',
};

@Component({
  selector: 'ey-icon-button',
  templateUrl: './ey-icon-button.component.html',
  styleUrls: ['./ey-icon-button.component.scss'],
})
export class EyIconButtonComponent {
  @Input() type = 'button';
  @Input() icon = null;
  @Input() showSpinner = false;
  @Input() btnClass: string = ButtonClass.primary;
  @Input() disabled = false;
  @Input() meta: IFieldMeta;
}
